import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Layout from "./components/Layout";
import Auth from "./components/Layout/Auth";
import Admin from "./components/Layout/Admin";
import PublicPortal from "./components/PublicPortal";
import CollaboratorPortal from "./components/CollaboratorPortal";
import ClientPortal from "./components/ClientPortal";
import CreateOffice from "./components/CreateOffice";
import Join from "./components/Join";
import Upgrade from "./components/Join/Payment/Upgrade";
import AccountActivation from "./components/Join/Payment/AccountActivation";
import PaymentSuccessRedirect from "./components/Join/PaymentSuccessRedirect";
import UpgradePaymentSuccessRedirect from "./components/Join/UpgradePaymentSuccessRedirect";
import Settings from "./components/Settings";
import News from "./components/News";
import Home from "./components/Home";
import HomeRedirect from "./components/Home/HomeRedirect";
import Login from "./components/Login";
import ResetPassword from "./components/Login/ResetPassword";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Pending from "./components/Home/Pending";
import Welcome from "./components/Home/Welcome";
import ManageFiduciary from "./components/ManageFiduciary";
import ManageApps from "./components/ManageApps";
import RejectInvitation from "./components/RejectInvitation";
import Privacy from "./components/Privacy";
import ManageServices from "./components/ManageServices";

import ManageSpecialties from "./components/ManageSpecialties";
import ContactMessages from "./components/ContactMessages";
import ManageRates from "./components/ManageRates";
import ManagePacks from "./components/ManagePacks";
import ManageMembership from "./components/ManageMembership";
import PackPage from "./components/PackPage";
import ExpiredLink from "./components/ExpiredLink";
import MemberShipSuccess from "./components/MemberShipSuccess";
import AccountSuspended from "./components/AccountSuspended";
import CollaboratorLimit from "./components/CollaboratorLimit";
import ManageAccount from "./components/ManageAccount";
import Invitation from "./components/Invitation";
import ClientInvitation from "./components/ClientInvitation";
import Themes from "./components/Themes";
import Domains from "./components/Domains";
import LoggedAs from "./components/LoggedAs";
import OeccbbHotels from "./components/OeccbbHotels";
import SubscriptionNewsletter from "./components/SubscriptionNewsletter";
import MyPacks from "./components/MyPacks";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="join" element={<Join />} />
            <Route path="settings" element={<Settings />} />
            <Route path="my-packs" element={<MyPacks />} />
            <Route path="news" element={<News />} />
            <Route path="collaborator" element={<CollaboratorPortal />} />
            <Route path="client" element={<ClientPortal />} />
            <Route path="create-office" element={<CreateOffice />} />
            <Route path="pending" element={<Pending />} />
            <Route path="welcome" element={<Welcome />} />
            <Route path="profile" element={<Profile />} />
            <Route path="upgrade" element={<Upgrade />} />
            <Route path="manage-subscription" element={<ManageAccount />} />
            <Route path="account-activation" element={<AccountActivation />} />
            <Route path="logged-as" element={<LoggedAs />} />
            <Route path="oeccbb-hotels" element={<OeccbbHotels />} />
          </Route>
          <Route path="membership-success" element={<MemberShipSuccess />} />
          <Route path="account-suspended" element={<AccountSuspended />} />
          <Route path="paymentSuccess" element={<PaymentSuccessRedirect />} />
          <Route
            path="upgradePaymentSuccess"
            element={<UpgradePaymentSuccessRedirect />}
          />
          <Route path="home" element={<HomeRedirect />} />
          <Route path="privacy" element={<Privacy />}>
            <Route path="cookies" element={<Privacy />} />
            <Route path="terms_of_use" element={<Privacy />} />
          </Route>
          <Route path="public/:SLUG/:ID" element={<PublicPortal />} />
          <Route element={<Auth />}>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="register" element={<Register />} />
            <Route path="rejectInvitation" element={<RejectInvitation />} />
            <Route path="expiredLink" element={<ExpiredLink />} />
            <Route path="invitation" element={<Invitation />} />
            <Route path="client-invitation" element={<ClientInvitation />} />
            <Route path="collaborator-limit" element={<CollaboratorLimit />} />
            <Route path="unsubscribe" element={<SubscriptionNewsletter />} />
          </Route>
          <Route element={<Admin />}>
            <Route path="manage-fiduciaries" element={<ManageFiduciary />} />
            <Route path="manage-apps" element={<ManageApps />} />
            <Route path="manage-services" element={<ManageServices />} />
            <Route path="manage-specialties" element={<ManageSpecialties />} />
            <Route path="contact-messages" element={<ContactMessages />} />
            <Route path="manage-packs" element={<ManagePacks />} />
            <Route path="packs/:packId" element={<PackPage />} />
            <Route path="manage-rates" element={<ManageRates />} />
            <Route path="manage-membership" element={<ManageMembership />} />
            <Route path="themes" element={<Themes />} />
            <Route path="domains" element={<Domains />} />
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
